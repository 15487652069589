<template>
  <div>
    <NavBar
      style="
        background-color: #fff;
        border-bottom: 1px solid rgb(238, 238, 238);
      "
    >
      <div slot="title">振合网平台服务协议</div>
    </NavBar>
    <div class="layout-page">
      <p class="details" v-html="noticeInfo"></p>
    </div>
  </div>
</template>

<script>
import NavBar from "@/layout/nav-bar/nav-bar";
import { getSysMessage } from "@/api/common";
export default {
  name: "agreement",
  components: {
    NavBar,
  },
  data() {
    return {
      noticeInfo: "",
      levelId:
        this.$route.query.levelId === undefined ? 1 : this.$route.query.levelId,
    };
  },
  mounted() {
    this.newsNotice();
  },
  methods: {
    async newsNotice() {
      const ret = await getSysMessage({ type: 2, level_id: this.levelId });
      if (ret.code * 1 == 1) {
        if (ret.data.length <= 0) {
          this.noticeInfo = "";
        } else {
          this.noticeInfo = ret.data;
        }
      }
    },
  },
};
</script>

<style lang="less" scoped>
.layout-page {
  background-color: #fff;
  min-height: 100vh;
}
.gray-color {
  color: #aaaaaa;
}
.blue-color {
  color: #0040ed;
  margin: 10px;
}
/deep/.details {
  width: 100%;
  padding: 56px 12px 0 12px;
  box-sizing: border-box;
  font-size: 14px;
  margin: 0;
  p {
    width: 100%;
    img {
      width: 100%;
      height: auto;
    }
  }
}
</style>
